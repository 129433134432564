import * as React from 'react';
import BlockContent from "@sanity/block-content-to-react";
import getSerializers from '@sanity/block-content-to-hyperscript/lib/serializers';
import Layout from '../components/layout';
import ContentMargins from '../components/ContentMargins';
import { Typography } from '../components/Typography'
import { graphql } from 'gatsby'
import Img from 'gatsby-image';


const defaultSerializers = getSerializers(React.createElement).defaultSerializers;


const serializers = {
  types: {
    block: (props) => {
      const DefaultBlockSerializer = defaultSerializers.types.block;
      return <ContentMargins>
        <Typography>
          <DefaultBlockSerializer {...props} />
        </Typography>
      </ContentMargins>
    }
  }
};


const KeyPointPageTemplate = (props) => {
  const page = props.data.keyPoint;
  return (
    <Layout
      pageTitle={"Wer wir sind"}
    >
      <ContentMargins>
        {(page.picture && page.picture.image) ? <Img
          fixed={page.picture.image.asset.fixed}
          style={{float: 'right', marginLeft: '30px', borderRadius: '2px'}}
        /> : null}

        <h1 style={{marginTop: '1em'}}>{page.title}</h1>
      </ContentMargins>

      {page.shortText ? <BlockContent
        blocks={page.shortText}
        serializers={serializers}
      /> : false}

      {page.pageContent ? <BlockContent
        blocks={page.pageContent}
        serializers={serializers}
      /> : false}
    </Layout>
  )
}


export default KeyPointPageTemplate;


export const pageQuery = graphql`
  query KeypointPageBySlug($id: String!) {
    keyPoint: sanityKeyPoint(id: { eq: $id }) {
      id,
      title,
      shortText: _rawShortText,
      pageContent: _rawPageContent,
      picture {
        image {
          asset {
            fixed(width: 400) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }      
    }
  }
`;